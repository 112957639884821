.stage-1 {
  background-image: url("../images/bg1.jpg");

  @include media("860px") {
    background-image: url("../images/bg1-mobile.jpg");
    //background-position: 0 275px;
    background-position: bottom center;
    background-repeat: no-repeat;
  }

  .logo {
    text-align: center;
    margin: 0 auto;

    img {
      max-width: 100%;
    }

    @include media("1440px") {
      width: 384px;
    }

    @include media("1366px") {
      width: 328px;
    }

    @include media("1280px") {
      width: 268px;
    }

    @include media("1024px") {
      width: 256px;
    }
  }

  .content {
    &-wrapper {
      max-width: 1033px;
      margin-left: auto;
      text-align: center;
      margin-right: 120px;
      padding-top: 60px;

      @include media("1440px") {
        max-width: 860px;
        padding-top: 50px;
      }

      @include media("1366px") {
        width: 734px;
        padding-top: 40px;
      }

      @include media("1280px") {
        width: 688px;
        padding-top: 36px;
      }

      @include media("1024px") {
        width: 572px;
        padding-top: 24px;
      }

      @include media("860px") {
        width: 100%;
        padding-top: 20px;
      }

      // debug
      //border: 1px dashed rgba(255,255,255, 0.5);

      .counter {
        border: 2px solid #ffcd06;
        max-width: 835px;
        margin: auto;
        margin-top: 43px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 216px;

        @include media("1440px") {
          max-width: 696px;
          height: 176px;
        }

        @include media("1366px") {
          max-width: 596px;
          height: 152px;
        }

        @include media("1280px") {
          max-width: 556px;
          height: 140px;
        }

        @include media("1024px") {
          max-width: 464px;
          height: 116px;
        }

        @include media("860px") {
          max-width: 320px;
          height: 80px;
          margin: 20px auto;
          margin-top: 32px;
        }

        &-item {
          font-size: 64px;
          display: flex;
          flex-direction: column;
          text-align: center;
          font-family: "Winner Sans Wide", sans-serif;
          font-weight: 500;
          width: 128px;

          @include media("1440px") {
            font-size: 52px;
            width: 104px;
          }

          @include media("1366px") {
            font-size: 44px;
            width: 88px;
          }

          @include media("1280px") {
            font-size: 42px;
            width: 84px;
          }

          @include media("1024px") {
            font-size: 36px;
            width: 72px;
          }

          @include media("860px") {
            font-size: 24px;
            width: 48px;
          }

          span {
            font-weight: bold;
            font-size: 20px;
            text-transform: uppercase;
            font-family: "Futura PT", sans-serif;

            @include media("1440px") {
              font-size: 18px;
            }

            @include media("1366px") {
              font-size: 16px;
            }

            @include media("1280px") {
              font-size: 14px;
            }

            @include media("1024px") {
              font-size: 12px;
            }

            @include media("860px") {
              font-size: 9px;
            }
          }
        }

        .separator {
          width: 28px;
          height: 28px;
          background-color: #ffcd06;
          border-radius: 100%;
          margin: 0 20px;
          margin-bottom: 20px;

          @include media("1440px") {
            width: 26px;
            height: 26px;
          }

          @include media("1366px") {
            width: 22px;
            height: 22px;
          }

          @include media("1280px") {
            width: 18px;
            height: 18px;
          }

          @include media("1024px") {
            width: 16px;
            height: 16px;
            margin: 0 10px;
            margin-bottom: 16px;
          }

          @include media("860px") {
            width: 8px;
            height: 8px;
            margin-bottom: 0;
          }

          @include media("860px") {
            margin: 0 10px;
            margin-bottom: 15px;
          }
        }
      }
    }

    &-frame {
      margin-top: 50px;

      @include media("860px") {
        border-top: 5px solid #ffcd06;
        border-bottom: 5px solid #ffcd06;
        background-color: rgba(0, 0, 0, 0.7);
        margin-top: 40px;
      }

      img {
        max-width: 100%;

        @include media("860px") {
          display: none;
        }
      }

      p {
        font-size: 38px;
        padding: 0 60px;
        margin: -20px 0;
        line-height: 1.3;

        @include media("1440px") {
          font-size: 32px;
          padding: 0 50px;
        }

        @include media("1366px") {
          font-size: 28px;
          padding: 0 40px;
        }

        @include media("1280px") {
          font-size: 26px;
          padding: 0 30px;
        }

        @include media("1024px") {
          font-size: 21px;
          padding: 0 20px;
        }

        @include media("860px") {
          font-size: 20px;
          margin: 0;
          padding: 20px;
        }
      }
    }

    .play-button {
      margin-top: 50px;
      margin-bottom: 50px;
    }
  }
}
