@import "normalize";

@import "../fonts/Futura/stylesheet.css";
@import "../fonts/WinnerSansWideMedium/stylesheet.css";

@mixin media($width) {
  @media (max-width: $width) {
    @content;
  }
}

#root {
  min-height: 100vh;
  position: relative;
}

body {
  background-color: #000;
  font-family: "Futura PT", sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  color: #fff;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  min-width: 360px;

  .logo {
    img {
      max-width: 100%;
    }
  }

  .play-button {
    background: url("../images/btn.svg") center center no-repeat;
    background-size: contain;
    color: #000;
    font-size: 44px;
    font-weight: bold;
    text-transform: uppercase;
    width: 400px;
    height: 104px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      background: url("../images/btn-hover.svg") center center no-repeat;
    }

    &:active {
      background: url("../images/btn-active.svg") center center no-repeat;
    }

    @include media("1440px") {
      width: 336px;
      height: 87px;
      font-size: 36px;
    }
    @include media("1366px") {
      width: 288px;
      height: 75px;
      font-size: 32px;
    }

    @include media("1280px") {
      width: 264px;
      height: 69px;
      font-size: 28px;
    }

    @include media("1024px") {
      width: 220px;
      height: 57px;
      font-size: 24px;
    }
  }

  .footer {
    background-color: #000;
    color: #808080;
    margin-top: auto;
    font-size: 14px;
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 9;

    @include media("860px") {
      font-size: 12px;
    }

    $size: 80px;

    &-wrapper {
      display: flex;
      align-items: center;
      height: $size;

      @include media("860px") {
        flex-wrap: wrap;
        height: 160px;
      }

      .copyright {
        margin-left: 30px;
        text-align: left;

        @include media("860px") {
          order: 1;
          margin-left: 20px;
          position: absolute;
          max-width: 240px;
          line-height: 1.25;
          top: 20px;
        }

        br {
          display: none;

          @include media("1024px") {
            display: initial;
          }
        }
      }

      .menu {
        margin-left: auto;
        display: flex;
        align-items: center;
        background-color: #000;

        @include media("860px") {
          order: 3;
          width: 100%;
          border-top: 1px solid rgba(#484848, 0.6);
          align-items: center;
          justify-content: space-around;
          height: 80px;
        }

        a {
          margin-left: 30px;
          color: #808080;
          text-decoration: none;
          text-align: center;

          @include media("860px") {
            margin-left: 0;
          }

          &:hover {
            color: #fff;
          }

          &:active {
            color: #4d4d4d;
          }
        }
      }

      .lang-switcher {
        border-left: 1px solid rgba(#484848, 0.6);
        margin-left: 30px;
        position: relative;
        font-family: "Winner Sans Wide", sans-serif;
        font-size: 12px;
        height: $size;
        width: $size;

        @include media("860px") {
          order: 2;
          margin-left: auto;
        }

        .current-lang {
          display: flex;
          align-items: center;
          justify-content: center;
          height: $size;
          width: $size;
          color: #fff;
          cursor: pointer;

          span {
            margin-right: 5px;
          }

          .icon {
            color: #808080;

            &.open {
              transform: rotate(180deg);
            }
          }
        }

        ul {
          list-style: none;
          display: flex;
          flex-direction: column;
          position: absolute;
          bottom: 100%;
          margin: 0;
          padding: 0;

          .lang {
            height: $size;
            width: $size;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            cursor: pointer;
            background-color: #000;
            border-bottom: 1px solid rgba(#484848, 0.6);

            &:hover {
              color: #000;
              background-color: #fff;
            }
          }
        }
      }
    }
  }

  .content {
    padding-bottom: 80px;
    @include media("860px") {
      padding-bottom: 180px;
    }
  }
}

.news {
  margin: 0 auto;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;

  .slider-wrapper {
    width: 1160px;
    margin: 0 auto;
    position: relative;

    @include media("1440px") {
      width: 972px;
    }

    @include media("1366px") {
      width: 860px;
    }

    @include media("1280px") {
      width: 812px;
    }

    @include media("860px") {
      width: auto;
      padding-left: 20px;
    }

    .ui-left,
    .ui-right {
      position: absolute;
      width: 382px;
      height: 68px;

      @include media("860px") {
        display: none;
      }
    }

    .ui-left {
      background: url("../images/news-ui-left.png") center center no-repeat;
      background-size: contain;
      left: -136px;
      top: -16px;
    }

    .ui-right {
      background: url("../images/news-ui-right.png") center center no-repeat;
      background-size: contain;
      right: -136px;
      top: -16px;
    }

    .navigation {
      position: absolute;
      right: 0;
      top: -50px;
      display: flex;

      @include media("1366px") {
        top: -40px;
      }

      @include media("860px") {
        right: 20px;
      }

      @include media("860px") {
        //top: -35px;
        display: none;
      }

      .prev,
      .next {
        cursor: pointer;
        background-color: #000;
        color: #ffcc00;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid #ffcc00;
        margin-left: 6px;

        @include media("1366px") {
          width: 30px;
          height: 30px;
        }

        @include media("1280px") {
          width: 26px;
          height: 26px;
          border: 1px solid #ffcc00;
        }

        @include media("860px") {
          width: 20px;
          height: 20px;
        }

        &:hover {
          color: #ffff07;
          border-color: #ffff07;
        }

        &:active {
          color: #ffc200;
          border-color: #ffc200;
        }
      }
    }
  }

  &-title {
    text-transform: uppercase;
    font-family: "Winner Sans Wide", sans-serif;
    font-weight: 500;
    color: #ffc000;
    font-size: 22px;
    margin-bottom: 12px;

    &.ru {
      font-family: "Futura PT", sans-serif;
    }

    @include media("1440px") {
      font-size: 20px;
    }

    @include media("1366px") {
      font-size: 18px;
    }

    @include media("1280px") {
      font-size: 16px;
    }
  }

  &-item {
    background-color: #ffc000;
    color: #000;
    width: 272px;
    display: block;
    text-decoration: none;
    height: 100%;

    &-wrapper {
      padding: 4px;
      @include media("1440px") {
        padding: 3px;
      }
      @include media("1366px") {
        padding: 2px;
      }
    }

    @include media("1440px") {
      width: 228px;
    }

    @include media("1366px") {
      width: 200px;
    }

    @include media("1280px") {
      width: 188px;
    }

    @include media("460px") {
      width: 228px;
    }

    &:hover {
      background-color: #fff101;
    }

    .image {
      // height: 152px;
      background-color: #000;

      img {
        max-width: 100%;
        display: block;
      }

      // @include media("1440px") {
      //   height: 126px;
      // }

      // @include media("1366px") {
      //   height: 111px;
      // }
      // @include media("1280px") {
      //   height: 105px;
      // }
    }

    .text {
      font-size: 16px;
      padding: 5px;
      padding-bottom: 10px;
      text-decoration: none;

      @include media("1366px") {
        font-size: 15px;
      }
      @include media("1280px") {
        font-size: 14px;
      }
    }
  }

  .swiper-slide {
    height: auto;
  }
}

@import "stage1";
@import "stage2";
@import "stage3";
@import "stage4";
