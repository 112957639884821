.stage-2 {
  background-image: url("../images/bg2.jpg");

  @include media("940px") {
    background-image: url("../images/bg2-mobile.jpg");
  }

  .play-button {
    position: absolute;
    top: 48px;
    left: 65px;

    @include media("1440px") {
      top: 40px;
      left: 52px;
    }

    @include media("1366px") {
      top: 34px;
      left: 44px;
    }

    @include media("1280px") {
      top: 32px;
      left: 43px;
    }

    @include media("1024px") {
      top: 26px;
      left: 32px;
    }

    @include media("940px") {
      position: initial;
      display: flex;
      margin: 0 auto;
      margin-top: 30px;
    }
  }

  .logo {
    max-width: 464px;
    margin: 0 auto;
    padding-top: 24px;
    @include media("1440px") {
      width: 384px;
      padding-top: 20px;
    }

    @include media("1366px") {
      width: 328px;
      padding-top: 17px;
    }

    @include media("1280px") {
      width: 268px;
      padding-top: 20px;
    }

    @include media("1280px") {
      padding-top: 12px;
    }
  }

  .content {
    text-align: center;
    position: relative;

    .social {
      position: absolute;
      top: 52px;
      right: 52px;
      display: flex;
      justify-content: space-between;

      @include media("1440px") {
        top: 38px;
        right: 42px;
      }

      @include media("1366px") {
        top: 30px;
        right: 40px;
      }

      @include media("1280px") {
        top: 30px;
        right: 30px;
      }

      @include media("1024px") {
        top: 24px;
        right: 20px;
      }

      @include media("940px") {
        position: initial;
        max-width: 300px;
        margin: 0 auto;
        margin-top: 20px;
        justify-content: center;
      }

      &-item {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: bold;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 10px;
        color: #fff;
        text-decoration: none;

        @include media("1366px") {
          font-size: 12px;
        }

        @include media("1280px") {
          font-size: 11px;
        }

        @include media("1024px") {
          font-size: 9px;
        }

        .icon {
          margin-bottom: 10px;
          width: 76px;
          height: 76px;
          background-color: #fff101;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            @include media("1366px") {
              transform: scale(0.8);
            }
            @include media("1280px") {
              transform: scale(0.7);
            }
            @include media("1024px") {
              transform: scale(0.6);
            }
          }

          &:hover {
            background-color: #ffff07;
          }

          @include media("1440px") {
            width: 67px;
            height: 67px;
          }

          @include media("1366px") {
            width: 63px;
            height: 63px;
          }

          @include media("1280px") {
            width: 54px;
            height: 54px;
          }

          @include media("1024px") {
            width: 46px;
            height: 46px;
          }
        }
      }
    }

    .video-frame {
      margin: 0 auto;
      max-width: 1346px;
      height: 662px;
      background: url("../images/video-frame.png") center center no-repeat;

      @include media("1440px") {
        height: 550px;
        width: 1120px;
        background-size: contain;
      }

      @include media("1366px") {
        margin-top: 20px;
        height: 470px;
        width: 956px;
        background-size: contain;
      }

      @include media("1280px") {
        margin-top: 15px;
        height: 441px;
        width: 897px;
        background-size: contain;
      }

      @include media("1024px") {
        margin-top: 10px;
        width: 748px;
        height: 369px;
        background-size: contain;
      }

      @include media("740px") {
        background: url("../images/frame-mobile.png") center center no-repeat;
        height: 236px;
        margin-top: 30px;
        width: auto;
      }

      iframe {
        margin-top: 87px;
        margin-right: 11px;
        width: 885px;
        height: 495px;

        @include media("1440px") {
          margin-top: 72px;
          height: 412px;
          width: 738px;
        }

        @include media("1366px") {
          margin-top: 61px;
          height: 352px;
          width: 632px;
        }

        @include media("1280px") {
          margin-top: 57px;
          height: 331px;
          width: 594px;
        }

        @include media("1024px") {
          margin-top: 48px;
          height: 277px;
          width: 498px;
        }

        @include media("740px") {
          margin-top: 30px;
          width: 320px;
          height: 180px;
          margin-right: 0;
        }
      }
    }

    .points {
      margin: 0 auto;
      margin-top: 20px;
      display: flex;
      justify-content: center;
      text-transform: uppercase;
      font-weight: bold;

      .point {
        position: relative;
        margin: 0 40px;

        @include media("1366px") {
          margin: 0 30px;
        }

        @include media("1280px") {
          margin: 0 25px;
          font-size: 14px;
        }

        @include media("740px") {
          margin: 0 10px;
          font-size: 10px;
        }

        &:after {
          position: absolute;
          top: 17px;
          left: 100%;
          content: "";
          display: block;
          width: 80px;
          height: 3px;
          background-color: #fecd06;

          @include media("1366px") {
            top: 15px;
            width: 92px;
            left: calc(100% - 16px);
          }

          @include media("1280px") {
            top: 12px;
            width: 78px;
            left: calc(100% - 14px);
            height: 2px;
          }

          @include media("740px") {
            height: 1px;
            width: 48px;
            top: 7px;
          }
        }

        &:last-of-type {
          &:after {
            display: none;
          }
        }

        &-icon {
          margin-bottom: 10px;

          img {
            @include media("1440px") {
              max-width: 35px;
            }

            @include media("1280px") {
              max-width: 28px;
            }

            @include media("740px") {
              max-width: 16px;
            }
          }
        }
      }
    }
  }
}
