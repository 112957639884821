.stage-3 {
  background-image: url("../images/bg3.jpg");

  @include media("940px") {
    background-image: url("../images/bg3-mobile.jpg");
  }

  .play-button {
    position: absolute;
    top: 48px;
    left: 65px;

    @include media("1440px") {
      top: 40px;
      left: 52px;
    }

    @include media("1366px") {
      top: 34px;
      left: 44px;
    }

    @include media("1280px") {
      top: 32px;
      left: 43px;
    }

    @include media("1024px") {
      top: 26px;
      left: 53px;
    }

    @include media("940px") {
      position: initial;
      margin: 0 auto;
      margin-top: 30px;
      margin-bottom: 40px;
      display: flex;
    }
  }

  .logo {
    max-width: 464px;
    margin: 0 auto;
    padding-top: 24px;

    @include media("1440px") {
      width: 384px;
      padding-top: 20px;
    }

    @include media("1366px") {
      width: 328px;
      padding-top: 17px;
    }

    @include media("1280px") {
      width: 268px;
      padding-top: 20px;
    }

    @include media("1024px") {
      padding-top: 12px;
    }

    @include media("940px") {
      padding-top: 20px;
    }
  }

  .social {
    position: absolute;
    top: 52px;
    right: 52px;
    display: flex;
    justify-content: space-between;

    @include media("1440px") {
      top: 38px;
      right: 42px;
    }

    @include media("1366px") {
      top: 37px;
      right: 40px;
    }

    @include media("1280px") {
      top: 30px;
      right: 33px;
    }

    @include media("1024px") {
      top: 30px;
      right: 30px;
    }

    @include media("940px") {
      position: initial;
      max-width: 300px;
      margin: 0 auto;
      margin-top: 20px;
      justify-content: center;
    }

    &-item {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: bold;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 10px;
      color: #fff;
      text-decoration: none;

      @include media("1366px") {
        font-size: 12px;
      }

      @include media("1280px") {
        font-size: 11px;
      }

      @include media("1024px") {
        font-size: 9px;
      }

      .icon {
        margin-bottom: 10px;
        width: 76px;
        height: 76px;
        background-color: #fff101;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          @include media("1366px") {
            transform: scale(0.8);
          }
          @include media("1280px") {
            transform: scale(0.7);
          }
          @include media("1024px") {
            transform: scale(0.6);
          }
        }

        &:hover {
          background-color: #ffff07;
        }

        @include media("1440px") {
          width: 67px;
          height: 67px;
        }

        @include media("1366px") {
          width: 54px;
          height: 54px;
        }

        @include media("1280px") {
          width: 54px;
          height: 54px;
        }

        @include media("1024px") {
          width: 46px;
          height: 46px;
        }
      }
    }
  }

  .wrapper {
    margin-left: 65px;
    margin-right: 65px;
    margin-top: 30px;

    @include media("1440px") {
      margin-left: 55px;
      margin-right: 55px;
    }

    @include media("1366px") {
      margin-left: 45px;
      margin-right: 45px;
    }

    @include media("1280px") {
      margin-left: 40px;
      margin-right: 40px;
    }

    @include media("860px") {
      margin-left: 18px;
      margin-right: 18px;
    }

    .flex {
      background-color: rgba(#000, 0.78);
      display: flex;
      max-width: 1792px;
      margin: 0 auto;

      @include media("860px") {
        flex-wrap: wrap;
      }
    }

    .left,
    .right {
      width: 50%;

      @include media("860px") {
        width: 100%;
      }
    }

    .left {
      &-wrapper {
        padding: 64px 20px 20px 72px;

        @include media("1440px") {
          padding: 40px 20px 20px 52px;
        }

        @include media("1366px") {
          padding: 38px 20px 20px 49px;
        }

        @include media("1280px") {
          padding: 36px 20px 20px 42px;
        }

        @include media("860px") {
          padding: 20px;
        }
      }

      .subtitle {
        font-size: 20px;
        font-weight: bold;
        text-transform: uppercase;

        @include media("1440px") {
          font-size: 16px;
        }

        @include media("1366px") {
          font-size: 14px;
        }

        @include media("1280px") {
          font-size: 12px;
        }

        @include media("860px") {
          font-size: 10px;
        }
      }

      .title {
        color: #ffcc00;
        font-size: 72px;
        line-height: 1.1;
        font-weight: bold;
        letter-spacing: 0.3px;
        margin-bottom: 32px;
        text-transform: uppercase;

        @include media("1440px") {
          font-size: 52px;
        }

        @include media("1366px") {
          font-size: 48px;
        }

        @include media("1280px") {
          font-size: 42px;
        }

        @include media("860px") {
          font-size: 32px;
          margin-bottom: 18px;
        }
      }

      .text {
        font-size: 28px;
        line-height: 1.3;

        @include media("1440px") {
          font-size: 22px;
          margin-right: 52px;
        }

        @include media("1366px") {
          font-size: 20px;
          margin-right: 48px;
        }

        @include media("1280px") {
          font-size: 18px;
          margin-right: 42px;
          line-height: 1.4;
        }

        @include media("860px") {
          font-size: 16px;
        }
      }
    }

    .right {
      border: 1px solid #ffcc00;
      position: relative;

      @include media("860px") {
        margin-top: 10px;
      }

      .video-ui {
        height: 30px;
        background: url("../images/ui-video.png") left top no-repeat;
        background-size: contain;
        position: absolute;
        top: -30px;
        width: 455px;
        left: 60px;

        @include media("1440px") {
          width: 364px;
          top: -24px;
        }

        @include media("1366px") {
          width: 318px;
          top: -21px;
        }

        @include media("1280px") {
          width: 303px;
          top: -20px;
        }

        @include media("1024px") {
          width: 242px;
          top: -16px;
        }

        @include media("940px") {
          width: 200px;
        }

        @include media("860px") {
          width: 150px;
          top: -10px;
        }
      }

      .iframe-wrapper {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      iframe {
        width: 100%;
        height: 100%;
        min-height: 500px;

        @include media("1440px") {
          min-height: 376px;
        }

        @include media("1366px") {
          min-height: 360px;
        }

        @include media("1280px") {
          min-height: 337px;
        }

        @include media("860px") {
          min-height: 180px;
        }
      }

      .video-text {
        margin-bottom: -52px;
        font-size: 38px;
        font-family: "Winner Sans Wide", sans-serif;
        text-transform: uppercase;
        font-weight: 500;
        text-align: center;
        color: #000;
        position: absolute;
        width: 100%;
        bottom: 0;

        &.ru {
          font-family: "Futura PT", sans-serif;
        }

        @include media("1440px") {
          font-size: 28px;
          bottom: 12px;
        }

        @include media("1280px") {
          font-size: 24px;
          bottom: 18px;
        }

        @include media("1024px") {
          font-size: 18px;
          bottom: 22px;
        }

        @include media("860px") {
          font-size: 13px;
          bottom: 33px;
        }
      }
    }

    .yellow-line {
      background: url("../images/line-yellow.png") right top no-repeat;
      background-size: cover;
      height: 56px;
      width: 100%;
      max-width: 1792px;
      margin: 0 auto;

      @include media("1440px") {
        height: 42px;
      }

      @include media("1366px") {
        height: 40px;
      }

      @include media("1280px") {
        height: 36px;
      }

      @include media("860px") {
        height: 20px;
      }
    }
  }
}
