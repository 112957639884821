.stage-4 {
  background-image: url("../images/bg4.jpg");

  @include media("940px") {
    background-image: url("../images/bg4-mobile.jpg");
  }

  .play-button {
    position: absolute;
    top: 48px;
    left: 65px;

    @include media("1440px") {
      top: 40px;
      left: 52px;
    }

    @include media("1366px") {
      top: 34px;
      left: 44px;
    }

    @include media("1280px") {
      top: 32px;
      left: 43px;
    }

    @include media("1024px") {
      top: 26px;
      left: 53px;
    }

    @include media("940px") {
      position: initial;
      margin: 0 auto;
      margin-top: 30px;
      margin-bottom: 40px;
      display: flex;
    }
  }

  .logo {
    max-width: 464px;
    margin: 0 auto;
    padding-top: 24px;

    @include media("1440px") {
      width: 384px;
      padding-top: 20px;
    }

    @include media("1366px") {
      width: 328px;
      padding-top: 17px;
    }

    @include media("1280px") {
      width: 268px;
      padding-top: 20px;
    }

    @include media("1024px") {
      padding-top: 12px;
    }

    @include media("940px") {
      padding-top: 20px;
    }
  }

  .social {
    position: absolute;
    top: 52px;
    right: 52px;
    display: flex;
    justify-content: space-between;

    @include media("1440px") {
      top: 38px;
      right: 42px;
    }

    @include media("1366px") {
      top: 37px;
      right: 40px;
    }

    @include media("1280px") {
      top: 30px;
      right: 33px;
    }

    @include media("1024px") {
      top: 30px;
      right: 30px;
    }

    @include media("940px") {
      position: initial;
      max-width: 300px;
      margin: 0 auto;
      margin-top: 20px;
      justify-content: center;
    }

    &-item {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: bold;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 10px;
      color: #fff;
      text-decoration: none;

      @include media("1366px") {
        font-size: 12px;
      }

      @include media("1280px") {
        font-size: 11px;
      }

      @include media("1024px") {
        font-size: 9px;
      }

      .icon {
        margin-bottom: 10px;
        width: 76px;
        height: 76px;
        background-color: #fff101;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          @include media("1366px") {
            transform: scale(0.8);
          }
          @include media("1280px") {
            transform: scale(0.7);
          }
          @include media("1024px") {
            transform: scale(0.6);
          }
        }

        &:hover {
          background-color: #ffff07;
        }

        @include media("1440px") {
          width: 67px;
          height: 67px;
        }

        @include media("1366px") {
          width: 54px;
          height: 54px;
        }

        @include media("1280px") {
          width: 54px;
          height: 54px;
        }

        @include media("1024px") {
          width: 46px;
          height: 46px;
        }
      }
    }
  }

  .wrapper {
    max-width: 1160px;
    margin: 0 auto;
    margin-top: 40px;

    @include media("1440px") {
      max-width: 972px;
    }

    @include media("1366px") {
      max-width: 860px;
    }

    @include media("1280px") {
      max-width: 812px;
      margin-top: 22px;
    }

    @include media("1024px") {
      max-width: 656px;
    }

    @include media("940px") {
      padding-left: 20px;
      padding-right: 20px;
    }

    .title-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      @include media("940px") {
        margin-bottom: 70px;
        justify-content: initial;
      }

      > a img {
        @include media("1440px") {
          max-width: 132px;
        }

        @include media("1366px") {
          max-width: 109px;
        }

        @include media("1280px") {
          max-width: 96px;
        }

        @include media("1024px") {
          max-width: 80px;
        }
      }

      .title {
        margin-left: 20px;
        font-weight: bold;
        text-transform: uppercase;

        span {
          color: #ffcc00;
          font-size: 58px;

          @include media("1440px") {
            font-size: 49px;
          }

          @include media("1366px") {
            font-size: 44px;
          }

          @include media("1280px") {
            font-size: 42px;
          }

          @include media("1024px") {
            font-size: 28px;
          }
        }

        .subtitle {
          color: #fff;
          font-size: 22px;
          line-height: 1.2;

          @include media("1440px") {
            font-size: 18px;
          }

          @include media("1366px") {
            font-size: 16px;
          }

          @include media("1280px") {
            font-size: 15px;
          }

          @include media("1024px") {
            font-size: 13px;
          }

          @include media("940px") {
            position: absolute;
            left: 0;
            //bottom: -33px;
          }

          a {
            color: #fff;

            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }

    .content-wrapper {
      border: 2px solid #ffcc00;
      background-color: rgba(#000000, 0.7);
      padding: 45px 40px;
      margin-top: 40px;

      @include media("1366px") {
        padding: 32px 36px;
      }

      @include media("1280px") {
        margin-top: 20px;
        padding: 30px 36px;
      }

      @include media("1024px") {
        padding: 25px 30px;
      }

      @include media("940px") {
        padding: 24px 15px;
      }

      .grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 50px;
        grid-template-rows: repeat(2, 1fr);

        &.ru {
          .item {
            .title {
              font-family: "Futura PT", sans-serif !important;
              text-transform: uppercase;
            }
          }
        }

        @include media("1366px") {
          grid-gap: 42px;
        }

        @include media("1024px") {
          grid-gap: 35px;
        }

        @include media("940px") {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
          //grid-template-columns: repeat(2, 1fr);
          //grid-gap: 10px;
          //grid-template-rows: repeat(3, 1fr);
        }

        .item {
          text-align: center;
          font-family: "Winner Sans Wide", sans-serif;

          @include media("940px") {
            width: 44%;

            &:nth-of-type(1) {
              order: 1;
            }

            &:nth-of-type(2) {
              order: 2;
            }

            &:nth-of-type(3) {
              order: 6;
            }

            &:nth-of-type(4) {
              order: 3;
            }

            &:nth-of-type(5) {
              order: 5;
            }

            &:nth-of-type(6) {
              order: 6;
            }
          }

          .number {
            font-size: 45px;
            color: #ffcc00;
            padding: 14px 0 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 6px;

            @include media("1440px") {
              font-size: 38px;
            }

            @include media("1366px") {
              font-size: 32px;
            }

            @include media("1280px") {
              font-size: 28px;
            }

            @include media("1024px") {
              font-size: 24px;
            }

            @include media("940px") {
              font-size: 20px;
              padding: 6px 0 2px;
            }

            span {
              font-size: 32px;
              margin-left: 5px;

              @include media("1440px") {
                font-size: 22px;
              }

              @include media("1366px") {
                font-size: 20px;
              }

              @include media("1280px") {
                font-size: 17px;
              }

              @include media("1024px") {
                font-size: 15px;
              }

              @include media("940px") {
                font-size: 12px;
              }
            }

            &.yellow {
              background-color: #ffcc00;
              color: #000;
            }
          }

          .title {
            font-size: 18px;

            @include media("1440px") {
              font-size: 18px;
            }

            @include media("1366px") {
              font-size: 14px;
            }

            @include media("1280px") {
              font-size: 13px;
            }

            @include media("1024px") {
              font-size: 12px;
            }

            @include media("940px") {
              font-size: 10px;
            }
          }
        }
      }
    }
  }

  .news {
    margin-top: 40px;
  }
}
