@font-face {
    font-family: 'Winner Sans Wide';
    src: url('WinnerSans-WideMedium.eot');
    src: url('WinnerSans-WideMedium.eot?#iefix') format('embedded-opentype'),
        url('WinnerSans-WideMedium.woff2') format('woff2'),
        url('WinnerSans-WideMedium.woff') format('woff'),
        url('WinnerSans-WideMedium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

